const Footer = () => {
  return (
    <article className="footer">
      <a href="https://github.com/Qt1Rbt/" target="_blank" rel="noreferrer">
        Quentin Robinet &copy;2024
      </a>
    </article>
  );
};

export default Footer;
