import Linkedin from "../../assets/images/socials/linkedin.svg";
import Github from "../../assets/images/socials/github.svg";

const NavigationSocials = () => {
  return (
    <section className="social-media">
      <a
        href="https://www.linkedin.com/in/quentin-robinet-fr/" target="_blank" rel="noreferrer"
      >
        <img src={Linkedin} alt="linkedin" />
      </a>
      <a href="https://github.com/Qt1Rbt" target="_blank" rel="noreferrer">
        <img src={Github} alt="github" />
      </a>
    </section>
  );
};

export default NavigationSocials;
