const About = () => {
  return (
    <section className="section-container" id="about">
      <h2>Quelques mots</h2>
      <p className="white-text">
        Initialement chargé de l'intégration, du déploiement et du support des
        solutions développées par AQUILAE, j’ai progressivement évolué vers un
        poste de développeur backend. Polyvalent et rigoureux, je serais en
        mesure de vous accompagner dans divers projets, allant de
        l’administration système au développement de logiciel.
      </p>
    </section>
  );
};

export default About;
