import iconAubelec from '../assets/images/exp/aubelec.png'
import iconAquilae from "../assets/images/exp/Aquilae_sigle.png";
import iconNamkin from "../assets/images/exp/namkin.png";

const experienceData = [
  {
    jobtitle: "Développeur / Resp. maintenance",
    companyname: "Namkin",
    companyicon: iconNamkin,
    companylink: "https://namkin.fr",
    duration: "Depuis octobre 2023",
    tasks: [
      {
        title: "Maintenance de projets Symfony",
        tech: ["PHP", "Symfony"],
        location: undefined,
        description: undefined,
        icon: undefined,
      },
      {
        title: "Dévelopement projet client",
        tech: ["MongoDB", "React", "React Native", "NestJS"],
        location: undefined,
        description: undefined,
        icon: null,
      },
    ],
  },
  {
    jobtitle: "Développeur / Informaticien",
    companyname: "Aquilae",
    companyicon: iconAquilae,
    duration: "juillet 2017 - octobre 2023",
    tasks: [
      {
        title: "Système de décomptage du Parvis",
        tech: ["MongoDB", "React", "React Native", "NestJS"],
        location: "Tour Eiffel",
        description: [
          "Développement C (modules à microcontrôleur)",
          "Communication TCP/IP, AES128 vers contrôleur (Fourniture d’images docker)",
          "Suivi de chantier / fourniture de documentation",
        ],
        icon: "eiffel.png",
      },
      {
        title:
          "Interfaçage centrale de contrôle d’accès/intrusion Aritech (ATSx500IP)",
        tech: ["C#", "MySQL", "REST", "SDK Propriétaire"],
        description: [
          "Gestion utilisateurs et profils contrôle d'accès et intrusion",
          "Récupération et modification d'états (Groupes, Zones, Portes, ...)",
          "Intéractions avec les solutions AQUILAE",
        ],
        icon: "UTC.png",
      },
      {
        title:
          "Intégration et assistance au développement des solutions AQUILAE",
        tech: [
          "FFmpeg",
          "GStreamer",
          "OpenCV",
          "Cuda",
          "TensorRT",
          "C++",
          "PHP",
          "VueJS",
          "JQuery",
          "Ubuntu",
        ],
        description: [
          "Dévellopement d'API WEB et d'interface spécialisées",
          "Administration système",
          "Dévellopement C++ divers",
        ],
      },
      {
        title: "Support et suivi client",
        tech: ["Prometheus", "AlertManager"],
        description: [
          "Contact régulier avec DSI et chargés de maintenance suivant les projets",
          "Gestion maintenance préventive, corrective et adaptative",
          "Monitoring des infras et logiciels on-premise via Prometheus & AlertManager ",
        ],
      },
    ],
  },
  {
    jobtitle: "Technicien recherche et développement",
    companyname: "Aubelec",
    companylink: "https://aubelec.fr",
    companyicon: iconAubelec,
    duration: "janvier - juillet 2017",
    tasks: [
      {
        title: "Programmation de modules à microcontroleur",
        tech: ["Atmega", "Controllino", "Wiegand"],
        location: undefined,
        description: [
          "Interfaçage lecteur STID, protocole WIEGAND",
          "Communication TCP/IP",
        ],
        icon: undefined,
      },
      {
        title: "Vidéoprotection",
        tech: ["Dahua", "Hikvision", "Axis"],
        location: undefined,
        description: [
          "Paramétrage et maintenance d'installations de vidéoprotection",
        ],
        icon: null,
      },
      {
        title: "Contrôle d'accès / Intrusion",
        tech: ["Aritech"],
        location: undefined,
        description: ["Paramétrage des solutions aritech IP"],
        icon: null,
      },
    ],
  },
];

const educationData = [
  {
    name: "AI-900 Azure AI Fundamentals",
    description: "Introductions aux solutions IA Azure",
    year: "2022",
  },
  {
    name: "AZ-900 Azure Fundamentals",
    status: "Introduction aux solutions cloud Azure",
    year: "2021",
  },
  {
    name: "Licence mécatronique",
    major: true,
    status: "IUT de Troyes, alternance (BAC +3)",
    year: "2017 - 2018",
  },
  {
    name: "DUT Génie électrique et informatique industrielle",
    status: "IUT de Troyes (BAC +2)",
    year: "2015 - 2017",
  },
];

export { experienceData, educationData };
