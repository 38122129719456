import DownloadIcon from "../../assets/images/download-icon.png";
import Resume from "../../assets/documents/cv-ano.pdf";

const Header = () => {
  return (
    <article className="header">
      <h1 className="header-font">Quentin Robinet</h1>
      <p className="gray-text">
        Développeur en poste chez <strong>Namkin</strong>
      </p>
      <a className="download-btn" href={Resume} download="Quentin-Robinet">
        <img
          src={DownloadIcon}
          alt="Téléchargement CV"
          className="download-img"
        />
        Télécharger mon CV
      </a>
    </article>
  );
};

export default Header;
