import {experienceData} from "../../data/experienceData"
import Chip from "../../components/Chip"

const Task = ({task}) => {
  return (
    <div className="task">
      <h4>{task.title}</h4>

      <div className="chips-container">
        {(task?.tech ?? []).map((tech, i) => (
          <Chip key={i}>{tech}</Chip>
        ))}
      </div>

      <ul className="white-text">
        {(task?.description ?? []).map((d, i) => (
          <li key={i}>{d}</li>
        ))}
      </ul>
    </div>
  );
}

const XP = ({xp})=>{
const {tasks, companyname, companyicon, duration, jobtitle} = xp

  return (
    <article className="xp">
      <div className="company-img-container">
        <img
          className="company-img"
          src={companyicon}
          alt={`${companyname} icon`}
        ></img>
      </div>

      <div className="container">
        <div className="jobtitle">
          <h3>{jobtitle}</h3>
          <div>{duration}</div>
        </div>

        <h4 className="company-name gray-text">
          <em>{companyname}</em>
        </h4>

        {tasks.map((task, i) => (
          <Task key={i} task={task}></Task>
        ))}
      </div>
    </article>
  );
}

const XPSection = () => {
  return (
    <section className="section-container" id="xp">
      <h2>Expériences</h2>
      {experienceData.map((data, i) => (
        <XP key={i} xp={data}></XP>
      ))}
    </section>
  );
};

export default XPSection;
