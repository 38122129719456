import About from "./About";
import Header from "./Header";
import XP from "./XP";
import { Footer } from "../../components";
// import ScrollButton from "../../components/ScrollButton";
import { useEffect } from "react";

const Mainpage = () => {
  useEffect(() => {
    document.title = "Quentin Robinet | Développeur";
  })
  return (
    <article className="main-page">
      <Header />
      <About />
      {/* <ScrollButton /> */}
      <XP/>
      <Footer />
    </article>
  );
};

export default Mainpage;
